import React, { useMemo } from 'react'
import Button from 'antd/es/button'
import Typography from 'antd/es/typography'
import classNames from 'classnames'

const { Paragraph } = Typography

export default function FreeTrialCta ({ rm, label, withoutSubline, onClick, className }) {
  const button = useMemo(() => {
    return (
      <Button
        onClick={onClick}
        size='large'
        type="primary"
        className={classNames('rc-cta', className)}
      >
        {label || 'Essayer gratuitement'}
      </Button>
    )
  }, [onClick, label, className])

  return (
    <div className='flex flex-col items-center'>
      {(onClick && button) || (
        <a href={`https://app.rocketchart.co/signup${rm ? `?rm=${rm}` : ''}`}>{button}</a>
      )}
      {!withoutSubline && <Paragraph className='rc-small-text text-gray-500'>15 jours gratuits</Paragraph>}
    </div>
  )
}
